
























































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddCustomer from '@/components/modals/AddCustomer.vue';
import ModalViewCustomer from '@/components/modals/ViewCustomer.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import {
  initRegisterWarrantyWithCustomer,
  initRegisterWarrantyDetails,
  resetRegisterWarrantyWithCustomer,
  RegisterWarrantyWithCustomer,
  RegisterWarrantyDetails
} from '@/types/api/RegisterWarrantyWithCustomer';
import debounce from 'debounce';
import Vue from 'vue';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/types/api/Response';
import { cleanupFilterRequest, createFilterRequest } from '@/types/api/FilterRequest';
import { CustomerListDetailResponse, CustomerListResponse } from '@/types/api/CustomerList';
export default Vue.extend({
  'name': 'DealerCustomerList',
  'components': {
    DealerDetail,
    DealerDualMenu,
    SortableTable,
    ModalAddCustomer,
    ModalViewCustomer
  },
  data () {
    const form = initRegisterWarrantyWithCustomer();

    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      form,
      'formErrorMessage': '',
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'customer.name',
          'display': 'Full Name',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customer.customerDetail.ic',
          'display': 'IC/Passport',
          'width': 200,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customer.phone',
          'display': 'Contact No.',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customer.email',
          'display': 'Email',
          'width': 220,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customer.customerDetail.Address.State',
          'display': 'State',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customer.createdAt',
          'display': 'Created Date',
          'width': 230,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'status',
          'display': 'Status',
          'width': 240,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 100,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': [],
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center text-uppercase font-weight-bold',
          'hasDisplay': true,
          'buttons': [],
          'classFn': (val) => {
            return val === 'REQUESTED' ? 'text-danger' : '';
          }
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-view',
              'event': 'view'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<CustomerListDetailResponse>,
      'debounceGetList': null as unknown,
      'currentViewingCustomerId': null as number | null
    };
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'form.warrantyTypeChoice' (val:number) {
      this.initializeFormWarrantyTypeChoice(val);
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('customer/list', {
          'params': getRequest
        })
        .then(({ data }) => {
          const response = data as CustomerListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    },
    addCustomer () {
      resetRegisterWarrantyWithCustomer(this.form);
      this.initializeFormWarrantyTypeChoice(this.form.warrantyTypeChoice!);
      (this.$refs.modalAddCustomer as any).resetSearchByNameAndSearchByIc();
      (this.$refs.modalAddCustomer as any).create();
    },
    submitForm () {
      this.form.isSubmitting = true;
      this.formErrorMessage = '';

      const clonedForm = Object.assign({} as RegisterWarrantyWithCustomer, this.form);
      if (this.form.warrantyTypeChoice === 1) {
        delete clonedForm.carRegistrationNo;
        delete clonedForm.chasisNo;
        delete clonedForm.make;
        delete clonedForm.model;
        delete clonedForm.colour;
      } else {
        delete clonedForm.buildingAddress;
        clonedForm.carRegistrationNo = clonedForm.carRegistrationNo + '';
        clonedForm.chasisNo = clonedForm.chasisNo + '';
        clonedForm.make = clonedForm.make + '';
        clonedForm.model = clonedForm.model + '';
        clonedForm.colour = clonedForm.colour + '';
      }
      clonedForm.name = clonedForm.name + '';
      clonedForm.phone = clonedForm.phone + '';
      clonedForm.email = clonedForm.email + '';
      clonedForm.ic = clonedForm.ic + '';
      clonedForm.invoice = clonedForm.invoice + '';

      delete clonedForm.warrantyTypeChoice;
      const momentInstallationDate = window.moment(
        clonedForm.installationDateUi,
        'DD/MM/YYYY'
      );
      clonedForm.installationDate = momentInstallationDate.toISOString();
      delete clonedForm.installationDateUi;
      clonedForm.warrantyDetails = [];

      this.form.warrantyDetails.forEach((wd) => {
        const clonedForm2 = Object.assign({} as RegisterWarrantyDetails, wd);
        clonedForm2.tintedLocation = clonedForm2.tintedLocation + '';
        delete clonedForm2.serialNo;
        delete clonedForm2.model;
        delete clonedForm2.warrantySealRunNo;

        const momentStartDate = window.moment(
          clonedForm2.startDateUi,
          'DD/MM/YYYY'
        );
        clonedForm2.startDate = momentStartDate.toISOString();
        delete clonedForm2.startDateUi;

        clonedForm2.totalSqFt = parseFloat(clonedForm2.totalSqFt + '' ?? '0');

        clonedForm.warrantyDetails.push(clonedForm2);
      });

      let postRequest = null as unknown;
      if (this.form.warrantyTypeChoice === 1) {
        postRequest = this.axios.post('warranty/building', clonedForm);
      } else {
        postRequest = this.axios.post('warranty/auto', clonedForm);
      }

      (postRequest as Promise<AxiosResponse<any>>).then(() => {
        (this.$refs.modalAddCustomer as any).hide();
        this.currentViewingCustomerId = null;
      }).catch((err) => {
        if (
          err.response &&
            err.response.status === 422 &&
            err.response.data
        ) {
          const result = err.response.data.result;
          if (result) {
            (this.$refs.modalAddCustomer as any).setErrors(result);
          }
        }
        const error = err.response.data as ApiResponse;

        this.formErrorMessage = error.message;

        if (error.result) {
          for (const errKey in error.result) {
            error.result[errKey].forEach((errMessage:string) => {
              this.formErrorMessage += '<br>';
              this.formErrorMessage += errMessage;
            });
          }
        }
      })
        .finally(() => {
          this.form.isSubmitting = false;
          this.getList();
        });
    },
    initializeFormWarrantyTypeChoice (warrantyType:number) {
      this.form.warrantyDetails = [];

      if (warrantyType === 1) {
        this.form.warrantyDetails.push(initRegisterWarrantyDetails());
        this.form.warrantyDetails.push(initRegisterWarrantyDetails());
        this.form.warrantyDetails.push(initRegisterWarrantyDetails());
        this.form.warrantyDetails.push(initRegisterWarrantyDetails());
      } else {
        this.form.warrantyDetails.push(initRegisterWarrantyDetails());
      }
    },
    deleteWarrantyDetails (index:number) {
      this.form.warrantyDetails.splice(index, 1);
    },
    addWarrantyDetails () {
      this.form.warrantyDetails.push(initRegisterWarrantyDetails());
    },
    viewCustomer (rowNumber: number) {
      this.currentViewingCustomerId = this.tableData[rowNumber].id;

      (this.$refs.modalViewCustomer as any).show();
    }
  }
});
